#landing_page_root * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Nunito Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#landing_page_root {
  color: #132220;
  background: #f3f3f3;
}
#landing_page_root p { 
  font-size: 14px; 
  color: #132220; 
  line-height: 1.4em;
}